import React from 'react'
import PropTypes from 'prop-types'

import SectionLink from './SectionLink'
import SectionTitle from './SectionTitle'


/**
 * For every section in `sections`, if there is a `name` found, that
 * value will be rendered as a <SectionTitle /> (it's basically a
 * subheader for a group of links).
 *
 * The current use seems to be that either all sections will have a
 * `name` or none of them will, though there is nothing enforcing
 * this.
 */
export default function AccordionSections({ sections }) {
  return sections.map(({ name: sectionName, links }, sectionIdx) => (
    <div
      key={ sectionIdx }
    >
      <SectionTitle
        title={ sectionName }
      />

      {
        links.map(({ featureFlag, id, name: linkName, url }) => (
          <SectionLink
            featureFlag={ featureFlag }
            id={ id }
            key={ id }
            name={ linkName }
            url={ url }
          />
        ))
      }
    </div>
  ))
}

AccordionSections.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      name: SectionTitle.propTypes.title,
      links: PropTypes.arrayOf(
        PropTypes.shape(SectionLink.propTypes)
      ).isRequired,
    })
  ).isRequired,
}

