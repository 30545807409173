import {
  useContext,
  useMemo,
} from 'react'

import FeatureFlagsContext from '../FeatureFlagsContext'


export default function useFeatureFlags({ flags, traits }) {
  const { flagsmith } = useContext(FeatureFlagsContext)

  const { isLoading } = flagsmith

  return useMemo(() => {
    const result = {}

    if ( isLoading ) {
      return result
    }

    for ( const flag of flags ) {
      if ( !flag ) {
        // eslint-disable-next-line no-continue
        continue
      }

      result[flag] = {
        enabled: flagsmith.hasFeature(flag),
        value: flagsmith.getValue(flag),
      }
    }

    if ( traits ) {
      for ( const trait of traits ) {
        if ( !trait ) {
          // eslint-disable-next-line no-continue
          continue
        }

        result[trait] = flagsmith.getTrait(trait)
      }
    }

    return result
  }, [flags, flagsmith, isLoading, traits])
}
