import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { useCurrentWebsiteID } from 'components/CurrentWebsiteIDProvider'

import {
  makeConsentManagementAccordion,
  makePoliciesAccordion,
  makeSettingsAccordion,
} from './config'

import findAccordionIDForPathname from './lib/findAccordionIDForPathname'


export default function useSidebarAccordions() {
  const currentWebsiteID = useCurrentWebsiteID()

  const { pathname } = useLocation()

  const accordions = useMemo(() => {
    // These need to be in this order.
    return [
      makeConsentManagementAccordion,
      makePoliciesAccordion,
      makeSettingsAccordion,
    ].map((fn) => fn({ currentWebsiteID }))
  }, [currentWebsiteID])

  const openedAccordionID = useMemo(() => {
    return findAccordionIDForPathname(accordions, pathname)
  }, [accordions, pathname])

  return {
    accordions,
    currentWebsiteID,
    openedAccordionID,
  }
}
